<template>
  <div class="">
    <!-- 在这里有一个视频语音通话区 -->
    <input v-model="custompeerid" type="text" />
    <button @click="callcustomer">我是服务器，连接</button>
    <div class="myvideo" v-if="iscalldiv">
      <video ref="localVideo" class="localvideo" autoplay muted></video>
      <video
        ref="remoteVideo"
        class="remotevideo"
        autoplay
        muted
        poster="https://img.duoxiangpai.com/wp-content/uploads/tupianbd/0/253/4206160135/2809124833.jpg"
      ></video>
      <div class="ringoffcss" @click="ringoff">
        <!-- <span v-if="!iscalltitle" class="iscalltitlecss">等待客服....</span> -->
        <i class="el-icon-phone ringoffbtn" v-if="iscall"></i>
      </div>
    </div>
  </div>
</template>

<script>
import Peer from "peerjs";
export default {
  name: "chat",
  data() {
    return {
      // 语音聊天部分
      localStream: null,
      remoteStream: null,
      servpeer: null,
      servpeerid: "",
      call: null,
      custompeerid: "",
      iscalldiv: false,
      iscall: false,
      // iscalltitle: false,
    };
  },

  mounted() {
    // 创建Peer实例，并设置一些配置项
    this.servpeer = new Peer();
    this.servpeer.on("open", (id) => {
      this.servpeerid = id; //获取到分配给一个peerid
    });
  },
  methods: {
    //*************************************** */
    //  呼叫客户
    //*************************************** */

    async callcustomer() {
      let that = this;
      this.iscalldiv = true;
      // let stream = null;

      // 获取本地音视频流
      this.localStream = await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: true,
      });

      // 在本地视频元素中显示本地流
      this.$refs.localVideo.srcObject = this.localStream;

      var call = this.servpeer.call(this.custompeerid, this.localStream);
      call.on("stream", function (remoteStream) {
        // Show stream in some video/canvas element.
        that.iscall = true;
        // that.iscalltitle = true;
        // console.log(remoteStream, "远程的视频流remoteStream");
        that.$refs.remoteVideo.srcObject = remoteStream;
      });
    },
    //*************************************** */
    // 关闭电话
    //*************************************** */
    ringoff() {
      this.servpeer.destroy();
      this.iscall = false;
    },
  },
};
</script>

<style scoped>
.myvideo {
  position: relative;
  width: 300px;
  height: 500px;
  background: #000;
}

.localvideo {
  margin: 0;
  padding: 0;
  /* display: block; */
  width: 100px !important;
  height: 140px !important;
  position: absolute;
  left: 10px;
  top: 10px;
  z-index: 100;
  /* transform: scale(1, 3); */
}
.remotevideo {
  margin: 0;
  padding: 0;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  transform: scale(1, 2);
}
/* .iscalltitlecss {
  color: #fff;
} */
.ringoffcss {
  position: absolute;
  bottom: 10%;
  left: 45%;
  cursor: pointer;
}
.ringoffbtn {
  font-size: 30px;
  color: red;
}
</style>
