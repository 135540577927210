<template>
  <div id="app">
    <!-- <chat /> -->
    <!-- 
    <router-link to="chat">chat</router-link> <br /><br /><br /><br />
    <router-link to="/chatservicer">chatservicer</router-link> -->

    <router-view></router-view>
  </div>
</template>

<script>
// import chat from "./components/chat.vue";
// import router from "./router/index";
export default {
  name: "App",
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
