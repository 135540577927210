import Vue from 'vue';
import VueRouter from 'vue-router';
import chat from "../components/chat.vue";
import chatservicer from "../components/chatservicer.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: '/chat',
    name: 'chat',
    component: chat,
  },
  {
    path: '/chatservicer',
    name: 'chatservicer',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component:chatservicer,
  },
 
];

const router = new VueRouter({
  routes,
});

export default router;
