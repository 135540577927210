<template>
  <div>
    <!-- 在这里有一个视频语音通话区 -->
    我是客户端
    <button @click="callservicer">点击</button>,我的peerid:{{ peerid }}
    <br />
    <div class="myvideo" v-if="iscalldiv">
      <video ref="localVideo" class="localvideo" autoplay muted></video>
      <video
        ref="remoteVideo"
        class="remotevideo"
        autoplay
        muted
        poster="https://img.duoxiangpai.com/wp-content/uploads/tupianbd/0/253/4206160135/2809124833.jpg"
      ></video>
      <div class="ringoffcss" @click="ringoff">
        <span v-if="!iscalltitle" class="iscalltitlecss">等待客服....</span>
        <i class="el-icon-phone ringoffbtn" v-if="iscall"></i>
      </div>
    </div>
  </div>
</template>

<script>
import Peer from "peerjs";
export default {
  name: "chat",
  data() {
    return {
      // 语音聊天部分
      localStream: null,
      remoteStream: null,
      peer: null,
      peerid: "",
      call: null,
      iscalldiv: false,
      iscall: false,
      iscalltitle: false,
    };
  },

  mounted() {
    // 创建Peer实例，并设置一些配置项
    this.peer = new Peer();
    this.peer.on("open", (id) => {
      this.peerid = id; //获取到分配给一个peerid
      // console.log(this.peerid, "mounted里面的peerid");
    });
  },
  methods: {
    async callservicer() {
      this.iscalldiv = true;

      localStorage.setItem("peerid", this.peerid);
      let that = this;
      try {
        // // 获取本地音视频流
        this.localStream = await navigator.mediaDevices.getUserMedia({
          audio: true,
          video: true,
        });

        // 在本地视频元素中显示本地流
        this.$refs.localVideo.srcObject = this.localStream;

        // 注册接收远程流的回调函数
        this.peer.on("call", (call) => {
          // 接听通话
          call.answer(that.localStream);

          // 在远程视频元素中显示远程流
          call.on("stream", (remoteStream) => {
            that.iscall = true;
            that.iscalltitle = true;
            that.remoteStream = remoteStream;
            that.$refs.remoteVideo.srcObject = that.remoteStream;
            // console.log(that.remoteStream, "  that.remoteStream 29422002");
          });
        });
      } catch (error) {
        console.error("Failed to start call:", error);
      }
    },
    // 关闭电话
    ringoff() {
      this.peer.destroy();
      this.iscall = false;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.myvideo {
  position: relative;
  width: 300px;
  height: 500px;
  background: #000;
}

.localvideo {
  margin: 0;
  padding: 0;
  /* display: block; */
  width: 100px !important;
  height: 140px !important;
  position: absolute;
  left: 10px;
  top: 10px;
  z-index: 100;
  /* transform: scale(1, 3); */
}
.remotevideo {
  margin: 0;
  padding: 0;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  transform: scale(1, 2);
}
.iscalltitlecss {
  color: #fff;
}
.ringoffcss {
  position: absolute;
  bottom: 10%;
  left: 45%;
  cursor: pointer;
}
.ringoffbtn {
  font-size: 30px;
  color: red;
}
</style>
